.NonCombat .Basics {
  white-space: nowrap;
  text-align: center;
}

.NonCombat .Basics > div {
  display: inline-block;
  margin: 0 var(--data-hgap);
  margin-bottom: var(--data-vgap-small);
}

.NonCombat .Languages {
  display: inline-block;
  white-space: normal;
}

.Languages .value + .value::before {
  content: ', ';
}
