.Abilities {
  text-align: center;

  .Proficiency {
    text-align: center;
    padding-bottom: var(--data-vgap);
  }

  .Proficiency .value::before {
    content: 'Proficiency Bonus: ';
  }

  .AbilityTable {
    margin-top: var(--data-vgap);
    margin-right: 1.5em;
    margin-left: auto;
  }

  .AbilityTable .AbilityName {
    text-align: left;
    font-size: 1.15em;
  }

  .AbilityTable {
    border-spacing: var(--data-vgap-small);
  }

  .AbilityTable .Detail .Name {
    vertical-align: top;
    text-align: right;
  }

  .AbilityTable .Detail .Modifier {
    vertical-align: top;
    text-align: left;
    min-width: 2.75em;
  }
}
