.Actions.Detailed .ActionsTable > div {
  grid-auto-rows: auto;
}

.Actions .ActionsTable {
  .ActionHeader {
    opacity: 0.5;

    tr:first-child td {
      border-top: none;
      vertical-align: bottom;
    }
  }

  > div {
    display: grid;
    grid-template-columns: 19fr 7.5fr 13fr 4.5fr 6fr;
    grid-auto-rows: 4em auto;
    break-inside: avoid;

    align-items: center;

    &.ActionHeader {
      align-items: end;
    }

    &:not(.ActionHeader) {
      border-top: 1px solid rgba(0, 0, 0, 0.2);
    }

    > div {
      padding: 0 0.5em;

      &:first-child {
        padding-left: var(--section-hpad);
      }

      &:last-child {
        padding-right: var(--section-hpad);
      }

      &.Description {
        padding-left: 2em;
        padding-bottom: var(--data-vgap);
        white-space: pre-wrap;
      }

      &:not(.Description) {
        padding-top: var(--data-vgap-small);
      }
    }

    .NameAndType .Name,
    .NameAndType .Type {
      display: block;
    }

    .NameAndType .Type {
      font-size: 0.9em;
    }

    .Effect,
    .RangeAndArea,
    .Requires,
    .ToHit {
      text-align: center;
    }

    .RangeAndArea > span,
    .Requires > span {
      display: block;
    }

    .ToHit .Attack .value::before {
      content: 'TO HIT';
    }

    .Effect .label,
    .ToHit .Attack .value::before,
    .ToHit .Save .label {
      display: block;
      font-size: 0.8em;
    }

    .Effect .Special,
    .ToHit .Special {
      font-weight: bold;
    }

    .Description {
      grid-column: 1 / 6;
    }
  }
}
/*
.ActionsTable {
  width: 100%;

  .ActionHeader {
    opacity: 0.5;
    tr:first-child td {
      border-top: none;
      vertical-align: bottom;
    }
  }

  td {
    padding: 0 0.5em;

    &:first-child {
      padding-left: var(--section-hpad);
    }

    &:last-child {
      padding-right: var(--section-hpad);
    }

    &.Description {
      padding-left: 2em;
      padding-bottom: var(--data-vgap);
      white-space: pre-wrap;
    }
  }

  tr:first-child td {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    padding-top: var(--data-vgap-small);
    height: 4em;
  }

  tr:last-child td {
    padding-bottom: var(--data-vgap-small);
  }

  .Action:last-child tr:last-child td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }

  .Effect,
  .NameAndType,
  .RangeAndArea,
  .Requires,
  .ToHit {
    white-space: nowrap;
  }

  .NameAndType .Name,
  .NameAndType .Type {
    display: block;
  }

  .NameAndType .Type {
    font-size: 0.9em;
  }

  .Effect,
  .RangeAndArea,
  .Requires,
  .ToHit {
    text-align: center;
  }

  .RangeAndArea > span,
  .Requires > span {
    display: block;
  }

  .ToHit .Attack .value::before {
    content: 'TO HIT';
  }

  .Effect .label,
  .ToHit .Attack .value::before,
  .ToHit .Save .label {
    display: block;
    font-size: 0.8em;
  }

  .Effect .Special,
  .ToHit .Special {
    font-weight: bold;
  }
}
*/
