.SpellDetails {
  .General .Detail {
    margin-bottom: var(--data-vgap);
    padding: 0 2em;
    font-style: italic;
  }

  tbody::after {
    content: '';
    display: block;
    break-inside: avoid-page;
    break-before: avoid-page;
    break-after: page;
  }
  tbody * {
    break-inside: avoid-page;
    break-before: avoid-page;
  }
}
