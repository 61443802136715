.InventoryDetails {
  .Details {
    text-align: center;

    > div {
      display: inline-block;
      margin: 0 var(--data-hgap) var(--data-vgap-small);
    }
  }

  .Equipment {
    margin-bottom: var(--data-vgap);

    .title {
      text-align: center;
      font-weight: bold;
      color: var(--primary-highlight-text-color);
    }

    .Item {
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      display: grid;
      grid-template-columns: 10rem auto;
      padding: var(--data-vgap-small) 0;

      &:last-child {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      }

      .Name {
        grid-column: 1;
        text-align: right;
        font-weight: bold;
        padding-right: 0.5em;
      }

      .Description {
        grid-column: 2;
        text-align: left;
        white-space: pre-wrap;

        .Attuned,
        .RequiresAttunement {
          font-weight: bold;
        }
      }
    }
  }
}
