.FeatDetails .Feats {
}

.FeatDetails .Feats .Feat {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  display: grid;
  grid-template-columns: 12rem auto;
  padding: var(--data-vgap-small) 0;
}
.FeatDetails .Feats .Feat:last-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.FeatDetails .Feats .Feat .Name {
  grid-column: 1;
  text-align: right;
  font-weight: bold;
  padding-right: 0.5em;
}

.FeatDetails .Feats .Feat .Description {
  grid-column: 2;
  text-align: left;
}
