html {
  font-size: 13px;
  overflow-y: scroll;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

table {
  border-spacing: 0;
}

th,
td {
  padding: 0;
}

input,
textarea,
select,
button {
  font-family: inherit;
}
