.InventoryDetails .Details {
  text-align: center;
}

.InventoryDetails .Details > div {
  display: inline-block;
  margin: 0 var(--data-hgap);
  margin-bottom: var(--data-vgap-small);
}

.InventoryDetails .Equipment {
  margin-bottom: var(--data-vgap);
}

.InventoryDetails .Equipment .title {
  text-align: center;
  font-weight: bold;
  color: var(--primary-highlight-text-color);
}

.InventoryDetails .Equipment .Item {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  display: grid;
  grid-template-columns: 10rem auto;
  padding: var(--data-vgap-small) 0;
}

.InventoryDetails .Equipment .Item:last-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.InventoryDetails .Equipment .Item .Name {
  grid-column: 1;
  text-align: right;
  font-weight: bold;
  padding-right: 0.5em;
}
.InventoryDetails .Equipment .Item .Description {
  grid-column: 2;
  text-align: left;
  white-space: pre-wrap;
}
