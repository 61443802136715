.Inventory {
  .Details {
    text-align: center;

    > div {
      display: inline-block;
      margin: 0 var(--data-hgap) var(--data-vgap-small);
    }
  }

  .Equipment {
    @extend %basic-list;

    .Item {
      @extend %basic-list-entry;

      .Count {
        font-weight: normal;

        &::before {
          content: ' (';
        }

        &::after {
          content: ')';
        }
      }

      &.RequiresAttunement {
        .Name::after {
          content: ' (A)';
          text-decoration: line-through;
          color: var(--not-attuned-color);
          font-size: 50%;
          vertical-align: text-top;
        }

        &.Attuned .Name::after {
          text-decoration: inherit;
          color: inherit;
        }
      }
    }
  }
}
