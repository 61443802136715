.Abilities,
.Skills {
  text-align: center;

  .Proficiency {
    text-align: center;
    padding-bottom: var(--data-vgap);
  }

  .Proficiency .value::before {
    content: 'Proficiency Bonus: ';
  }

  .Column {
    display: inline-block;
    vertical-align: top;
    + .Column {
      margin-left: 2em;
    }
  }

  .Ability,
  .Skill {
    text-align: left;

    + .Ability {
      margin-top: var(--data-vgap-large);
    }

    > * {
      margin-top: var(--data-vgap-small);
      display: block;

      + * {
        margin-left: var(--data-hgap);
      }
    }

    .AbilityName {
      text-align: left;
      font-size: 1.1em;
    }

    .Detail {
      text-align: left;

      .Name {
        vertical-align: top;
        text-align: right;
      }

      .Ability {
        font-size: 60%;
        vertical-align: middle;
      }

      .Modifier {
        vertical-align: top;
        text-align: left;
      }
    }
  }
}
