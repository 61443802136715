.ProficiencyDetails .Proficiencies {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: var(--data-vgap-small) var(--data-hgap);
}

.ProficiencyDetails .Proficiencies .Proficiency {
  text-align: left;
  font-weight: bold;
}
