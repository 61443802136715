.BasicDetails {
  position: relative;
  min-height: calc(var(--portrait-height) + var(--section-border-width) * 2);

  .Portrait {
    height: var(--portrait-height);
    width: var(--portrait-width);
    position: absolute;
    left: 0;
    top: 0;
  }

  .PhysicalAttributes {
    margin-top: var(--data-vgap);
    margin-left: var(--portrait-width);
    text-align: center;

    > div {
      display: inline-block;
      margin: 0 var(--data-hgap);
    }

    .Age .value::before {
      content: 'Age: ';
    }

    .Height .value::before {
      content: 'Height: ';
    }

    .Weight .value::before {
      content: 'Weight: ';
    }

    > div.Size {
      margin-left: 0;

      .value {
        &::before {
          content: '(Size: ';
        }

        &::after {
          content: ')';
        }
      }
    }

    .Hair .value::before {
      content: 'Hair: ';
    }

    .Eyes .value::before {
      content: 'Eyes: ';
    }

    .Skin .value::before {
      content: 'Skin: ';
    }

    .Marks .value::before {
      content: 'Physical Traits: ';
    }
  }

  .Details {
    margin-left: var(--portrait-width);

    > * {
      font-size: 1.5rem;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      color: var(--primary-text-color);
      white-space: nowrap;
    }

    ::before {
      display: block;
      font-size: 1rem;
      font-weight: normal;
      color: var(--hint-text-color);
    }

    .Name::before {
      content: 'Name:';
    }

    .Race::before {
      content: 'Race:';
    }

    .Class::before {
      content: 'Class:';
    }
  }
}
