.Rest .Basics {
  white-space: nowrap;
  text-align: center;
}

.Rest .Basics > div {
  display: inline-block;
  margin: 0 var(--data-hgap);
  margin-bottom: var(--data-vgap-small);
}

.Rest .HitDice .value::before {
  content: 'Hit Dice: ';
}

.Rest .MaxHitDice .value::before {
  content: 'Max Hit Dice: ';
}
