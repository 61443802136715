.PrintableCharacterSheet {
  --primary-highlight-text-color: rgba(0, 0, 0, 0.87);
  --modifier-plus-color: var(--primary-highlight-text-color);
  --modifier-even-color: var(--primary-text-color);
  --modifier-minus-color: var(--primary-highlight-text-color);

  border: 1px solid black;
  text-align: left;
  margin: auto;
  padding: 0.5in;
  width: 8.5in;
  display: block;

  > .break-after {
    border-bottom: 2px dashed red;
    margin-top: var(--section-vgap);
    margin-bottom: var(--section-vgap);
  }
  @media print {
    border: 0;
    margin: 0;
    padding: 0;

    > .break-after {
      page-break-after: always;
      border: none;
      margin: none;
    }
  }

  .section {
    border: var(--section-border-width) solid rgba(0, 0, 0, 0.25);
    padding: var(--section-vpad) var(--section-hpad);

    > .title {
      font-weight: bold;
      font-size: 1.5em;
      color: var(--primary-text-color);
      text-align: center;
      padding-bottom: 0.25rem;
    }
  }

  > %section-like + %section-like {
    margin-top: var(--section-vgap);
  }

  .grid,
  .section {
    @extend %section-like;
  }

  .grid {
    display: grid;
    grid-gap: var(--section-vgap) var(--section-hgap);

    &.two-column {
      grid-template-areas: 'a a';
    }
  }

  .evenlySpacedRow {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 1rem;
  }

  .evenlySpacedRow > * {
    grid-row: 1;
  }

  .highlight {
    font-weight: bold !important;
    color: var(--primary-highlight-text-color) !important;
  }

  .label,
  .value::before {
    font-weight: bold;
    color: var(--secondary-text-color);
  }

  .value {
    font-weight: bold;
    color: var(--modifier-even-color);
  }

  .secondary {
    .label,
    .value::before {
      font-weight: normal;
      color: var(--hint-text-color);
    }

    .value {
      font-weight: bold;
      color: var(--secondary-text-color);
    }
  }

  .tertiary {
    .label,
    .value,
    .value::after,
    .value::before {
      font-weight: normal;
      color: var(--hint-text-color);
    }
  }

  .modifier.even {
    color: var(--modifier-even-color);
  }

  .modifier.plus {
    color: var(--modifier-plus-color);
  }

  .modifier.minus {
    color: var(--modifier-minus-color);
  }

  --basic-list-columns: 6;
  %basic-list {
    display: grid;
    grid-template-columns: repeat(var(--basic-list-columns), auto);
    margin-bottom: var(--data-vgap);
    grid-gap: var(--data-vgap-small) var(--data-hgap);
    margin-bottom: var(--extra-write-area-height);

    .title {
      grid-column: 1 / calc(var(--basic-list-columns) + 1);
      text-align: center;
      font-weight: bold;
      color: var(--primary-highlight-text-color);
    }

    %basic-list-entry {
      text-align: left;
      font-weight: bold;
    }
  }

  @import './BasicDetails.scss';
  @import './Personality.scss';
  @import './Abilities.scss';
  @import './Inventory.scss';
  @import './ProficiencyDetails.scss';
  .Notes {
    height: 1in;
    border-bottom: none;
  }

  @import 'CombatNonCombatShared.scss';
  @import 'Combat.scss';
  @import 'NonCombat.scss';
  @import 'Rest.scss';
  @import 'SpellDetails.scss';
  @import 'InventoryDetails.scss';
  @import 'FeatDetails.scss';
}
/*
.PrintableCharacterSheet .Grid {
    display: grid;
    grid-template-columns: 16rem auto;
    grid-template-rows: fit-content(100%);
    grid-gap: var(--section-vgap) var(--section-hgap);
}

.PrintableCharacterSheet .SpellDetails {}

.PrintableCharacterSheet .InventoryDetails {}

.PrintableCharacterSheet .FeatDetails {}

*/
/*

*/
