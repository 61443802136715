.Combat .Basics,
.Combat .HP {
  display: block;
  white-space: nowrap;
  text-align: center;
}

.Combat .Basics > div,
.Combat .HP > div {
  display: inline-block;
  margin: 0 var(--data-hgap);
  margin-bottom: var(--data-vgap-small);
}

.Combat .Initiative .value::before {
  content: 'Initiative: ';
}

.Combat .AC .value::before {
  content: 'Armor Class (AC): ';
}

.Combat .MaxHP .value::before {
  content: 'Max Health (HP): ';
}
