.ActionsTable {
  width: 100%;
}

.ActionsTable td:first-child {
  padding-left: 0;
}

.ActionsTable td:last-child {
  padding-right: 0;
}

.ActionsTable td {
  padding: 0 0.5em;
}

.Action td.Description {
  padding-left: 2em;
  padding-bottom: var(--data-vgap);
  white-space: pre-wrap;
}

.Action td:first-child {
  padding-left: var(--section-hpad);
}

.Action td:last-child {
  padding-right: var(--section-hpad);
}

.Action tr:first-child td {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding-top: 2px;
}

.Action tr:last-child td {
  padding-bottom: 2px;
}

.Action:last-child tr:last-child td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.Action .RangeAndArea {
  width: 5.5rem;
}

.Action .ToHit {
  width: 3.5rem;
}

.Action .Effect {
  width: 6.5rem;
}

.Detailed .Action .NameAndType {
  width: 1px;
}

.Action .Requires {
  width: 1px;
}

.Action .NameAndType,
.Action .Requires,
.Action .RangeAndArea,
.Action .ToHit,
.Action .Effect {
  white-space: nowrap;
}

.Action .Spacer {
  width: 99rem;
}

.Action .NameAndType .Name,
.Action .NameAndType .Type {
  display: block;
}

.Action .NameAndType .Type {
  font-size: 0.9em;
}

.Action .Requires,
.Action .RangeAndArea,
.Action .ToHit,
.Action .Effect {
  text-align: center;
}

.Action .Requires,
.Action .RangeAndArea {
  font-size: 0.9em;
}

.Action .Requires > span,
.Action .RangeAndArea > span {
  display: block;
}

.Action .ToHit .Attack .value::before {
  content: 'TO HIT';
}

.Action .ToHit .Attack .value::before,
.Action .ToHit .Save .label,
.Action .Effect .label {
  display: block;
  font-size: 0.8em;
}

.Action .ToHit .Special,
.Action .Effect .Special {
  font-weight: bold;
}
