.CharacterSheet {
  border: 1px solid black;
  text-align: left;
  padding: 0.25rem;
  margin: 0.5rem auto;
  width: 56rem;
  display: block;
  .Grid {
    display: grid;
    grid-template-columns: 16rem auto;
    grid-template-rows: fit-content(100%);
    grid-gap: var(--section-vgap) var(--section-hgap);
  }

  @import './BasicDetails.scss';
  .BasicDetails {
    grid-column: 1 / 3;
    grid-row: 1;
  }

  @import './Abilities.scss';
  .Abilities {
    grid-column: 1;
    grid-row: 3 span;
  }

  @import './CombatNonCombatShared.scss';

  @import './Combat.scss';
  .Combat {
    grid-column: 2;
  }

  @import './NonCombat.scss';
  .NonCombat {
    grid-column: 2;
  }

  @import './Rest.scss';
  .Rest {
    grid-column: 2;
  }

  @import './Inventory.scss';
  .Inventory {
    grid-column: 2 span;
  }

  @import './Personality.scss';
  .Personality {
  }

  @import './SpellDetails.scss';
  .SpellDetails {
  }

  @import './InventoryDetails.scss';
  .InventoryDetails {
  }

  @import './FeatDetails.scss';
  .FeatDetails {
  }

  @import './ProficiencyDetails.scss';
  .ProficiencyDetails {
  }

  .section {
    border: var(--section-border-width) solid rgba(0, 0, 0, 0.25);
    padding: var(--section-vpad) var(--section-hpad);
  }

  .Grid ~ .section {
    margin-top: var(--section-vgap);
  }

  .section > .title {
    font-weight: bold;
    font-size: 1.5em;
    color: var(--primary-text-color);
    text-align: center;
    padding-bottom: 0.25rem;
  }

  .evenlySpacedRow {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 1rem;
  }

  .evenlySpacedRow > * {
    grid-row: 1;
  }

  .highlight {
    font-weight: bold !important;
    color: var(--primary-highlight-text-color) !important;
  }

  .label,
  .value::before {
    font-weight: bold;
    color: var(--secondary-text-color);
  }

  .value {
    font-weight: bold;
    color: var(--modifier-even-color);
  }

  .secondary .label,
  .secondary .value::before {
    font-weight: normal;
    color: var(--hint-text-color);
  }

  .secondary .value {
    font-weight: bold;
    color: var(--secondary-text-color);
  }

  .tertiary .label,
  .tertiary .value,
  .tertiary .value::before,
  .tertiary .value::after {
    font-weight: normal;
    color: var(--hint-text-color);
  }

  .modifier.even {
    color: var(--modifier-even-color);
  }

  .modifier.plus {
    color: var(--modifier-plus-color);
  }

  .modifier.minus {
    color: var(--modifier-minus-color);
  }
}
