:root {
  --primary-text-color: rgba(0, 0, 0, 1);

  --primary-highlight-text-color: rgba(100, 100, 210, 1);

  --link-color: rgba(50, 110, 150, 1);
  --link-color-alpha: rgba(50, 110, 150, 0);

  --secondary-text-color: rgba(0, 0, 0, 0.7);

  --disabled-text-color: rgba(0, 0, 0, 0.5);
  --hint-text-color: var(--disabled-text-color);

  --modifier-plus-color: rgba(0, 150, 0, 1);
  --modifier-even-color: var(--primary-text-color);
  --modifier-minus-color: rgba(150, 0, 0, 1);

  --not-attuned-color: rgba(200, 0, 0, 1);

  --section-hpad: 0.75rem;
  --section-hpad-neg: calc(0 - var(--section-hpad));
  --section-vpad: 0.75rem;
  --section-vpad-neg: calc(0 - var(--section-vpad));
  --section-vgap: 0.2rem;
  --section-hgap: 0.2rem;
  --section-border-width: 1px;

  --portrait-width: 100px;
  --portrait-height: var(--portrait-width);

  --data-vgap-small: 0.25rem;
  --data-vgap: 0.5rem;
  --data-vgap-large: 0.75rem;
  --data-hgap: 0.5rem;

  --extra-write-area-height: 3rem;
}
