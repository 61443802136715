.Inventory {
  --inv-columns: 6;
  --inv-title-span: calc(var(--inv-columns) + 1);
}
.Inventory .Details {
  text-align: center;
}

.Inventory .Details > div {
  display: inline-block;
  margin: 0 var(--data-hgap);
  margin-bottom: var(--data-vgap-small);
}

.Inventory .Equipment {
  display: grid;
  grid-template-columns: repeat(var(--inv-columns), auto);
  margin-bottom: var(--data-vgap);
  grid-gap: var(--data-vgap-small) var(--data-hgap);
}

.Inventory .Equipment .title {
  grid-column: 1 / var(--inv-title-span);
  text-align: center;
  font-weight: bold;
  color: var(--primary-highlight-text-color);
}

.Inventory .Equipment .Item {
  text-align: left;
}

.Inventory .Item .Count::before {
  content: ' (';
}

.Inventory .Item .Count::after {
  content: ')';
}

.Inventory .Item .Count {
  display: inline;
}

.Inventory .Item .Name {
  display: inline;
  font-weight: bold;
}

.Inventory .Item.RequiresAttunement.Attuned .Name::after {
  text-decoration: inherit;
  color: inherit;
}

.Inventory .Item.RequiresAttunement .Name::after {
  content: ' (A)';
  text-decoration: line-through;
  color: var(--not-attuned-color);
  font-size: 50%;
  vertical-align: text-top;
}
