.Spells-SpellSlotsAndPoints {
  display: block;
  white-space: nowrap;
  text-align: center;
  margin-bottom: var(--data-vgap-small);
}

.Spells-SpellSlot {
  display: inline-block;
  margin: 0 0.5rem;
}

.Spells-SpellSlots-Level {
  margin-left: 0.9rem;
}

.Spells-SpellLevels::before {
  content: 'Spell Slots';
  font-weight: bold;
  color: var(--secondary-text-color);
}
.Spells-SpellSlots-Level.L0 .value::before {
  content: 'Cantrip: ';
}
.Spells-SpellSlots-Level.L1 .value::before {
  content: '1st: ';
}
.Spells-SpellSlots-Level.L2 .value::before {
  content: '2nd: ';
}
.Spells-SpellSlots-Level.L3 .value::before {
  content: '3rd: ';
}
.Spells-SpellSlots-Level.L4 .value::before {
  content: '4th: ';
}
.Spells-SpellSlots-Level.L5 .value::before {
  content: '5th: ';
}
.Spells-SpellSlots-Level.L6 .value::before {
  content: '6th: ';
}
.Spells-SpellSlots-Level.L7 .value::before {
  content: '7th: ';
}
.Spells-SpellSlots-Level.L8 .value::before {
  content: '8th: ';
}
.Spells-SpellSlots-Level.L9 .value::before {
  content: '9th: ';
}

.Spells-SpellSlots {
  display: block;
  margin: 0 0.75rem;
  margin-top: var(--data-vgap-small);
}

.Spells-SpellSlots .details {
  font-style: italic;
  display: inline-block;
  margin-left: 0.5rem;
}

.Spells-SpellSlots {
  display: block;
  margin: 0 0.75rem;
  margin-top: var(--data-vgap-small);
}

.Spells-SpellSlots .details {
  font-style: italic;
  display: inline-block;
  margin-left: 0.5rem;
}
