@import 'Common/reset.css';
@import 'Common/variables.css';

.App {
  text-align: center;
  box-sizing: border-box;
}
.App * {
  box-sizing: inherit;
}

.App.grid {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;
  height: 100vh;
}

.App.grid > .cell {
  overflow-y: scroll;
  overflow-x: auto;
}

.updateNotice {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border: 1px solid #c93;
  background: #fea;
  padding: 2em;
  border-radius: 1em;

  font-weight: bold;
}

a {
  color: inherit;
  text-decoration: none;
  background: linear-gradient(
    to top,
    var(--link-color-alpha) 5%,
    var(--link-color) 10%,
    var(--link-color-alpha) 20%
  );
}

.Buttons {
  width: 100%;
  text-align: center;
  padding: 0.25rem;
}

@media print {
  .Buttons {
    display: none;
  }
  .Links {
    display: none;
  }
}

.Buttons > button {
  width: calc(21% - 1rem);
  margin: 0.25rem;
  color: var(--secondary-text-color);
  border: 1px solid var(--secondary-text-color);
  background-color: inherit;
  border-radius: 2px;
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1.25rem;
}

.Buttons > button:nth-child(5) {
  width: calc(15% - 1rem);
}

.Buttons > button:nth-child(5) ~ button {
  width: auto;
  font-size: 0.9rem;
  font-weight: bold;
}

.Buttons > button.selected {
  font-weight: bold;
  color: var(--primary-text-color);
  border: 1px solid var(--primary-text-color);
}

.printableToggle.on {
  font-weight: bold;
  color: var(--primary-text-color);
  border: 1px solid var(--primary-text-color);
}

.npc {
  font-size: 0.9rem;
  font-weight: bold;
  margin: 0.25rem 0.5rem;
  display: inline-block;
  color: var(--secondary-text-color);
}
.npc a {
  display: inline-block;
  text-decoration: none;
  background: none;
  border: 1px solid var(--secondary-text-color);
  padding: 0.125rem 0.25rem;
  margin: 0.125rem;
  border-radius: 2px;
}

.monsterLink {
  font-size: 0.9em;
  margin: 0.125rem;
  display: inline-block;
  text-decoration: none;
  color: var(--secondary-text-color);
  border: 1px dotted var(--secondary-text-color);
  padding: 0.125rem 0.25rem;
  border-radius: 2px;
  background: none;
}
